
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap'
import './newGame.scss';
import GameService from '../Services/GameService';
import PlatformsService from '../Services/PlatformsService';
import { Platform } from '../Models/Platform';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom';
import AuthService from '../Services/AuthService';

interface Plat {
    platform: Platform,
    active: boolean
}

export const EditGame = () => {

    const history = useHistory();
    

    const [plataforms, setPlataforms] = useState([] as Plat[]);

    interface game {
        title: string,
        cover: string,
        description: string,
        developer: string,
        genre: string,
        launchDate: string,
    }

    const [values, setValues] = useState({
        title: "",
        cover: "",
        description: "",
        developer: "",
        genre: "",
        launchDate: "",

    } as game);

    let { id } = useParams() as any;

    useEffect(() => {
        PlatformsService.getPlatforms().then(v => {
            var t = [] as Plat[];
            v.forEach(p => {
                t.push({
                    platform: p,
                    active: false
                })
            })
            setPlataforms(t);
            GameService.getById(id).then(g => {
                var d = (new Date(g.launchDate));
                setValues({
                    title: g.title,
                    cover: g.cover,
                    description: g.description,
                    developer: g.developer,
                    genre: g.genre,
                    launchDate: `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate() + 1).padStart(2, '0')}`
                });
                g.platforms.forEach(p => {
                    var el = plataforms.find(x => x.platform.id === p.id);
                    if(el) {
                        el.active = true;
                        plataforms[plataforms.indexOf(el)] = el;
                        setPlataforms([...plataforms]);
                    }
                });
            });
        });
        // eslint-disable-next-line
    }, [id])

    const handleChange = (event: any) => {
        event.persist();
        setValues(values => ({
            ...values,
            [event.target.name]: event.target.value

        }));

    }

    if(!AuthService.isAdmin) {
        history.goBack();
        return <></>
    }

    const handleChangeNewGame = (event: any) => {
        if(!(
            values.title && values.cover &&
            values.description && values.developer && values.genre &&
            values.title !== "" && values.cover !== "" &&
            values.description !== "" && values.developer !== "" && values.genre !== ""
        )) {
            alert("Porfavor llena todos los campos");
            return;
        }
        try {
            new Date(values.launchDate);
        } catch(e) {
            alert('Please enter a valid date');
            return;
        }
        event?.preventDefault();
        var p = [] as Platform[];
        plataforms.forEach(pla => {
            if (pla.active) {
                p.push(pla.platform);
            }
        });
        if (p.length === 0) {
            alert("You must select at least one platform");
            return;
        }
        AuthService.getToken().then(t => {
            GameService.updateGame(
                id,
                values.title, values.cover,
                values.description, values.developer, values.genre,
                new Date(values.launchDate), p, t
            ).then((g) => {
                history.replace('/catalog');
            }).catch(() => {
                alert("Algo salió mal, intenta mas tarde");
            })
        })
    }

    const changeCheckbox = (pId: number) => {
        var c = plataforms.find(x => x.platform.id === pId);
        c.active = !c.active;
        var index = plataforms.indexOf(c);
        plataforms[index] = c;
        setPlataforms([...plataforms]);
    }


    return (
        <Form className="espacio">
            <h1 className="marginTop h4 text-center mb-4">Editar Juego</h1>
            <div className="row">
                <div className=" col">
                    <label className="grey-text">
                        Titulo
    </label>
                    <Form.Group>
                        <Form.Control type="text" name="title" value={values.title} placeholder="Título" onChange={handleChange} />
                    </Form.Group>

                </div>
                <br />
                <div className="col">
                    <label className="grey-text">
                        Desarrollador
    </label>
                    <Form.Group>
                        <Form.Control type="text" name="developer" value={values.developer} placeholder="Desarrollador" onChange={handleChange} />
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label className="grey-text">
                        Genero
    </label>

                    <Form.Group>
                        <Form.Control type="text" name="genre" value={values.genre} placeholder="Genero" onChange={handleChange} />
                    </Form.Group>
                </div>
                <br />
                <div className="col">
                    <label className="grey-text">
                        Fecha de lazamiento
    </label>

                    <Form.Group>
                        <Form.Control type="date" name="launchDate" value={values.launchDate} placeholder="Fecha de Lanzamiento" onChange={handleChange} />
                    </Form.Group>

                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label className="grey-text">
                        Sinopsis
    </label>
                    <Form.Group>
                        <Form.Control type="text" name="description" value={values.description} placeholder="Descripción" onChange={handleChange} />
                    </Form.Group>
                </div>
                <br />
                <div className="col">
                    <label className="grey-text">
                        Image
    </label>
                    <Form.Group>
                        <Form.Control type="text" name="cover" value={values.cover} placeholder="Portada" onChange={handleChange} />
                    </Form.Group>
                </div>
                <br />
                <br />
            </div>

            <div className="col">
                {

                    plataforms.map((p) => {
                        return (<div className="form-check-inline">
                            <label className="form-check-label">
                                <input type="checkbox" className="form-check-input" value="" onClick={() => changeCheckbox(p.platform.id)} checked={p.active} />{p.platform.name}
                            </label>
                        </div>
                        );
                    })
                }
            </div>
            <div>
                <Button className="col espacioBoton" type="" onClick={handleChangeNewGame} >Nuevo</Button>
            </div>
        </Form>
    );
};

