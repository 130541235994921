import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import styled, { css } from 'styled-components'

const EstadoUserJuego = (props: { state: boolean }) => {
  const Bandera = styled.span`
     
    background: linear-gradient(90deg, rgb(22, 119, 22) 0%, rgb(16, 214, 49) 100%);
    margin-bottom:30px;

    ${props => props.danger && css`
    background: linear-gradient(90deg, rgb(151, 22, 13) 0%, rgb(231, 15, 15) 100%);
    margin-bottom:30px;
    `}
  `;

  if (props.state) return <Bandera className="badge badge-success">Jugable en tu PC</Bandera>;
  return <Bandera danger className="badge badge-danger">No Jugable en tu PC</Bandera>;
}

export default EstadoUserJuego;