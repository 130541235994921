import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, FormControl, CardColumns, Spinner, Row } from 'react-bootstrap'
import { Game } from '../Components/Cards'
import GameService from '../Services/GameService';
import { Game as GameModel } from '../Models/Game';
import { Link } from 'react-router-dom';
import './Catalog.scss'

export const Catalog = () => {

  const [searchText, setSearchText] = useState("");
  const [games, setGames] = useState([] as GameModel[])
  const [data, setData] = useState(games);

  const excludeColumns = ["id", "image"];

  const handleChange = (value: any) => {
    setSearchText(value);
    filterData(value);
  };

  const filterData = (value: any) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(games);
    else {
      const filteredData = games.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : (item as any)[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  }

  useEffect(() => {
    GameService.getAll().then(d => {
      setGames(d);
      setData(d);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Container>
        <br></br>
      <FormControl type="text" placeholder="Search" value={searchText} onChange={e => handleChange(e.target.value)} className="col-12"
          style={{
            marginBottom: '30px',
            width: '100%'
          }} />
          {
            data.length === 0 &&
              <Row className='center-items'>
                <Spinner animation="border" role="status"></Spinner>
              </Row>
                
          }
        <CardColumns>
          {data.map((game) => {
            return <Link to={`/game/${game.id}`} key={game.id} ><Game key={game.id}
              name={game.title}
              information={game.description}
              image={game.cover}
            /></Link>
          })}
        </CardColumns>
      </Container>

      {
        /*
      }
      <Container fluid>
          <Row> 
            { data.map( (game) => {
              return <Game key={ game.id }
              name={ game.name }
              information={ game.information }
              image={ game.image }
            />
          })}
          </Row>
      </Container>
      */
      }
    </div>
  )
}



