import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Row, Container, FormControl, CardColumns, Spinner } from 'react-bootstrap'
import LibraryService from '../Services/LibraryService';
import AuthService from '../Services/AuthService';
import { Link, useHistory } from 'react-router-dom';
import { LibraryGame as LibModel } from '../Models/LibraryGame';
import { Game } from '../Components/Cards';

export const Library = () => {
  const authUser = AuthService;
  const history = useHistory();

  const [searchText, setSearchText] = useState("");
  const [games, setGames] = useState([] as LibModel[])
  const [data, setData] = useState([] as LibModel[]);

  const excludeColumns = ["id", "image"];

  const handleChange = (value: any) => {
    setSearchText(value);
    filterData(value);
  };

  useEffect(() => {
    authUser.getToken().then((idToken) => {
      LibraryService.getLibraryGames(idToken).then(d => {
        setGames(d);
        setData(d);
      });
    }).catch(e => history.replace('/auth/login'))
    // eslint-disable-next-line
  }, [])

  const filterData = (value: any) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(games);
    else {
      const filteredData = games.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : (item as any)[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  }

  return (
    <div className="App">
      <Container>
        <br></br>
        <FormControl type="text" placeholder="Search" value={searchText} onChange={e => handleChange(e.target.value)} className="col-12"
          style={{
            marginBottom: '30px',
            width: '100%'
          }} />
        {
          data.length === 0 &&
          <>
          <Row className='center-items'>
            <Spinner animation="border" role="status"></Spinner>
            <br></br>
          </Row>
          <Row>
            <p style={{ textAlign: 'center', marginTop: '20px' }}>Para añadir un juego a la biblioteca haz clic en el icono de tu plataforma en la pantalla de detalle.</p>
          </Row>
          </>

        }
        <CardColumns>
          {data.map((game) => {
            return <Link to={`/game/${game.game.id}`} key={game.game.id} ><Game key={game.game.id}
              name={game.game.title}
              information={game.game.description}
              image={game.game.cover}
            /></Link>
          })}
        </CardColumns>
      </Container>
    </div>
  )
}

