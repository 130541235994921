import Axios from "axios";
import { Game } from "../Models/Game";
import { Platform } from "../Models/Platform";

class PlatformService {

    url: string = "https://api.gamegalaxy.appyond.com/game";

    getAll(): Promise<Game[]> {
        return Axios.get(this.url).then((data) => {
            return data.data as Game[];
        });
    }

    getById(gameId: number): Promise<Game> {
        return Axios.get(this.url + '/' + gameId).then((data) => {
            return data.data as Game;
        });
    }

    removeGame(gameId: number, idToken: string): Promise<void> {
        return Axios.delete(this.url + '/' + gameId , {
            headers: {
                Authorization: "Bearer " + idToken
            }
        });
    }

    addGame(title: string, cover: string,
        description: string, developer: string,
        genre: string, launchDate: Date,
        platforms: Platform[], idToken: string): Promise<Game> {
        return Axios.post(this.url, {
            title: title,
            cover: cover,
            description: description,
            developer: developer,
            genre: genre,
            launchDate: launchDate,
            platforms: platforms
        }, {
            headers: {
                Authorization: "Bearer " + idToken
            }
        }).then((data) => {
            return data.data as Game;
        });
    }

    updateGame(gameId: number, title: string, cover: string,
        description: string, developer: string,
        genre: string, launchDate: Date,
        platforms: Platform[], idToken: string): Promise<Game> {
        return Axios.post(this.url + '/' + gameId, {
            title: title,
            cover: cover,
            description: description,
            developer: developer,
            genre: genre,
            launchDate: launchDate,
            platforms: platforms
        }, {
            headers: {
                Authorization: "Bearer " + idToken
            }
        }).then((data) => {
            return data.data as Game;
        });
    }

}

export default new PlatformService();