import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Components/videos.scss'
const Video1 = (props: { id: string }) => {
    
  return (
    <div className="video col embed-responsive embed-responsive-16by9">
      <iframe title="Embeds Page" className="embed-responsive-item" src={`https://www.youtube.com/embed/${props.id}`}
        allowFullScreen></iframe>
    </div>
  )
}

export default Video1;