import Axios from "axios";
import { Component } from "../Models/Component";

class ComponentsService {

    url: string = "https://api.gamegalaxy.appyond.com/components";

    getAll(): Promise<Component[]> {
        return Axios.get(this.url).then((data) => {
            return data.data as Component[];
        });
    }

    getById(id: number): Promise<Component> {
        return Axios.get(this.url + '/' + id).then((data) => {
            return data.data as Component;
        });
    }

    getCPU(): Promise<Component[]> {
        return Axios.get(this.url + '/cpu').then((data) => {
            return data.data as Component[];
        });
    }

    getGPU(): Promise<Component[]> {
        return Axios.get(this.url + '/gpu').then((data) => {
            return data.data as Component[];
        });
    }

}

export default new ComponentsService();