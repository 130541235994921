import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled , { css } from 'styled-components'
import AuthService from '../Services/AuthService';
import GameService from '../Services/GameService';

const EraseButton = (props: { id: number }) => {

  const history = useHistory();

  const Button = styled.button`
  background: linear-gradient(90deg, rgb(182, 179, 28) 0%, rgb(231, 217, 15) 100%);
  margin-bottom:15px;
  margin-top: 10px;

  ${props => props.eliminar && css`
    background: linear-gradient(90deg, rgb(151, 22, 13) 0%, rgb(231, 15, 15) 100%);
    margin-bottom:15px;
    color: white;
    margin-left: 10px;
  `}
`;

const deleteGame = () => {
  AuthService.getToken().then(t => {
    GameService.removeGame(props.id, t).then(() => {
      history.replace('/catalog');
    }).catch(e => {
      alert("Ha ocurrido un error inesperado, intenta más tarde");
    })
  })
}

    return (
      <>
            <Link to={`/admin/game/edit/${props.id}`}><Button className="medidor2 buttons btn btn-danger" >Editar</Button></Link>
            <Button eliminar className="medidor2 buttons btn btn-danger" onClick={deleteGame}>Eliminar</Button>
      </>
    )
  }
  
  export default EraseButton;