import Axios from "axios";
import { LibraryGame } from "../Models/LibraryGame";

class LibraryService {

    url: string = "https://api.gamegalaxy.appyond.com/library";

    getLibraryGames(idToken: string): Promise<LibraryGame[]> {
        return Axios.get(this.url, {
            headers: {
                Authorization: "Bearer " + idToken
            }
        }).then((data) => {
            return data.data as LibraryGame[];
        });
    }

    addToLibrary(gameId: number, platformId: number, idToken: string): Promise<LibraryGame[]> {
        return Axios.post(this.url, {
            game: gameId,
            platform: platformId
        }, {
            headers: {
                Authorization: "Bearer " + idToken
            }
        }).then((data) => {
            return data.data as LibraryGame[];
        });
    }

    removeFromLibrary(gameId: number, platformId: number, idToken: string): Promise<LibraryGame[]> {
        return Axios.delete(this.url + '/' + gameId + '/' + platformId, {
            headers: {
                Authorization: "Bearer " + idToken
            }
        }).then((data) => {
            return data.data as LibraryGame[];
        });
    }

}

export default new LibraryService();