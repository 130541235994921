import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NavigationBar } from '../Components/NavigationBar';
import { Catalog } from './Catalog';
import { GamePage } from './GamePage';
import { EditGame } from './editGame';
import { Library } from './Library';
import { FormPage } from './newGame';

export class Home extends Component {
    
    render() {
        return (
            <>
                <NavigationBar/>
                <Switch>
                    <Route path="/catalog" component={ Catalog }/>
                    <Route path="/library" component={ Library }/>
                    <Route path="/game/:id" component={ GamePage } />
                    <Route path="/admin/game/new" component={ FormPage} />
                    <Route path="/admin/game/edit/:id" component={ EditGame} />
                </Switch>
                <p  style={
                    {
                        marginTop: '30px',
                        marginBottom: '20px',
                        textAlign: 'center'
                    }
                }>Dream Team &reg;. Todos los derechos reservados</p>
                <br></br>
            </>
        );
    }
    
}
