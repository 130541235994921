import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Platform } from '../Models/Platform';
import AuthService from '../Services/AuthService';
import LibraryService from '../Services/LibraryService';
import './IconsBar.scss';

interface Plat {
    platform: Platform;
    has: boolean;
}

const IconsBar = (props: { g: number, p: Platform[] }) => {

    const [plats, setPlats] = useState([] as Plat[]);

    useEffect(() => {
        var sub = AuthService.userObservable.subscribe(x => {
            if (x.games) {
                AuthService.getToken().then(t => {
                    LibraryService.getLibraryGames(t).then(gg => {
                        var userGame = gg.find(y => y.game.id === props.g);
                        var tempPlats2 = [] as Plat[];
                        if (userGame) {
                            props.p.forEach(e => {
                                if (userGame.platforms && userGame.platforms.find(x => x.id === e.id)) {
                                    tempPlats2.push({
                                        platform: e,
                                        has: true
                                    })
                                } else {
                                    tempPlats2.push({
                                        platform: e,
                                        has: false
                                    })
                                }
                            });
                            setPlats(tempPlats2);
                        }
                        sub.unsubscribe();
                    })
                })
            }
        });
        var tempPlats = [] as Plat[];
        props.p.forEach(e => {
            tempPlats.push({
                platform: e,
                has: false
            });
        });
        setPlats(tempPlats);
        AuthService.updateUserInfo();

        return (() => {
            AuthService.updateUserInfo();
        })

    }, [props]);

    const history = useHistory();

    const handleClick = (platId) => {
        if (!AuthService.signedIn) {
            history.push('/auth/login');
            return;
        }
        var pl = plats.find(x => x.platform.id === platId);
        if (pl.has) {
            AuthService.getToken().then(t => {
                LibraryService.removeFromLibrary(props.g, platId, t)
                    .then(() => {
                        var index = plats.indexOf(pl);
                        plats[index].has = false;
                        setPlats([...plats]);
                    });
            });
        } else {
            AuthService.getToken().then(t => {
                LibraryService.addToLibrary(props.g, platId, t)
                    .then(() => {
                        var index = plats.indexOf(pl);
                        plats[index].has = true;
                        setPlats([...plats]);
                    });
            });
        }
    }

    return (
        <>

            <div>
                {
                    plats.map(x => {
                        return (
                            <>
                                <img
                                    key={x.platform.id}
                                    className={`icon ${x.has ? "selected" : ""}`}
                                    src={x.platform.icon}
                                    onClick={() => handleClick(x.platform.id)}
                                    alt={x.platform.name}>
                                </img>
                            </>
                        )
                    })
                }
            </div>

            {
                //   <div>
                //         <div className="form-check-inline">
                //             <label className="form-check-label">
                //             <input type="checkbox" className="form-check-input" value="false"/><img src={Xbox} className=" img-fluid Iconlogos" alt="logo" />
                //             </label>
                //         </div>
                //         <div className="form-check-inline">
                //             <label className="form-check-label">
                //             <input type="checkbox" className="form-check-input" value="true"/><img src={PlayStation} className=" img-fluid Iconlogos" alt="logo" />
                //             </label>
                //         </div>
                //         <div className="form-check-inline">
                //             <label className="form-check-label">
                //             <input type="checkbox" className="form-check-input" value="true" disabled/><img src={Nintendo} className=" img-fluid Iconlogos" alt="logo" />
                //             </label>
                //         </div>
                //   </div>
            }
        </>
    );
}

export default IconsBar;