import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ScrollToTop } from "../Components";
import { Home } from "../Pages/Home";
import { AuthRoutes } from "../views/Auth";

const ApplicationRoutes = () => {
    return (
        <Router>
            <ScrollToTop>
                <Switch>
                    <Route path="/auth" component={AuthRoutes} />
                    <Redirect to="/catalog" from="/" exact />
                    <Route path="/" component={ Home }/>
                </Switch>
            </ScrollToTop>
        </Router>
    );
}

export default ApplicationRoutes;