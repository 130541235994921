import Axios from "axios";
import { YoutubeSearchResults } from "../Models/YoutubeSearchResults";

class MediaService {

    url: string = "https://api.gamegalaxy.appyond.com/media/game/";

    getVideos(gameId: number): Promise<YoutubeSearchResults> {
        return Axios.get(this.url + gameId).then((data) => {
            return data.data as YoutubeSearchResults;
        });
    }

}

export default new MediaService();