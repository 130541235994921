import { Component } from "./Component";
import { Platform } from "./Platform";

export class Game {
    id?: number;
    title?: string;
    cover?: string;
    description?: string;
    developer?: string;
    genre?: string;
    launchDate?: Date;
    platforms?: Platform[];
    minGPU?: Component;
    minCPU?: Component;
    recGPU?: Component;
    recCPU?: Component;
}