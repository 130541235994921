import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/auth";
import { IoArrowBackCircle } from "react-icons/io5";
import { Form} from 'react-bootstrap';
import AuthService from "../../../Services/AuthService";

interface UserData {
    email: string;
    password: string;
}

const Login = () => {
    const history = useHistory();
    const [incorrect, setIncorrect] = useState(false);
    const [values, setValues] = useState({
        email: "",
        password: ""
    } as UserData);

    const redirectToCatalog = useCallback(() => {
       history.replace("/catalog");
    }, [history])

    useEffect(() => {
        if(AuthService.signedIn) {
            redirectToCatalog();
        } else {
            var sub = AuthService.signedInObservable.subscribe(s => {
                sub.unsubscribe();
                if(s) redirectToCatalog();
            })
        }


        firebase
        .auth()
        .getRedirectResult()
        .then(result => {
            if (!result || !result.user || !firebase.auth().currentUser) {
                return;
            }

            return setUserProfile().then(() => {
                redirectToCatalog();
            });
        })
        .catch(error => {
            console.log(error, 'error');
        });
    }, [redirectToCatalog]);

    const setUserProfile = async () => {
        firebase.auth().currentUser!;
    }

    const handleChangeToSignup = () => {
       history.push("/auth/signup");
    }

    const handleChange = (event: any) => {
        event.persist();
        setValues(values => ({
            ...values, 
            [event.target.name]: event.target.value
        }));
    }

    const handleLogin = (event: any) => {
        event.preventDefault();
        firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .then(() => {
            history.replace("/catalog");
        })
        .catch(error => {
            setIncorrect(true);
        });
    }

    return (
        <div className="log">
            <div className="gameGaTitle">
                <h1 className="gameGalaxy">Game</h1>
                <h1 className="gameGalaxy">Galaxy</h1>
            </div>
            <Form className="logForm" onSubmit={handleLogin}>
            <IoArrowBackCircle onClick={redirectToCatalog} className="backIcon"></IoArrowBackCircle>
                <h1 className="logTitle">Iniciar sesión</h1>
                <Form.Group controlId="formGroupEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" value={values.email}  placeholder="Ingresa tu email" onChange={handleChange}/>
                </Form.Group>
                <Form.Group controlId="formGroupPassword">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control type="password" name="password" value={values.password} placeholder="Ingresa tu contraseña" onChange={handleChange}/>
                </Form.Group>
                {incorrect && <p style={{color: "red"}}>{"El email o la contraseña son incorrectos."}</p>}
                <div className="submitBtn">
                    <div className="center">
                    <button className="btnH">
                        <svg width="180px" height="40px" viewBox="0 0 180 40">
                        <polyline points="179,1 179,39 1,39 1,1 179,1" className="bg-line" />
                        <polyline points="179,1 179,39 1,39 1,1 179,1" className="hl-line" />
                        </svg>
                        <span>Ingresar</span>
                    </button>
                    </div>
                </div>
                <div className="textS text-center p-2">
                    ¿No tienes una cuenta?
                </div>
                <div className="changeBtn">
                    <div className="center">
                    <button className="btnH" onClick={handleChangeToSignup}>
                        <svg width="100px" height="30px" viewBox="0 0 100 30">
                        <polyline points="99,1 99,29 1,29 1,1 99,1" className="bg-line" />
                        <polyline points="99,1 99,29 1,29 1,1 99,1" className="hl-line" />
                        </svg>
                        <span>Crear cuenta</span>
                    </button>
                    </div>
                </div>

            </Form>
     
        </div>
    );
}

export default Login;