import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card } from 'react-bootstrap'
import './Card.scss'

export const Game = (props : any) => {
    return (
        < >
            <Card className='cardGame' bg='secondary'  style={{ width: '100%' }} >
                <Card.Img src={ props.image } variant="top" style={{ width: '94%', marginTop: '3%', marginLeft: '3%'}} />
                <Card.Body>
                    <Card.Title>{ props.name }</Card.Title>
                </Card.Body>
            </Card>
        </>
    )
}


