import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/auth";
import { IoArrowBackCircle } from "react-icons/io5";
import { Form } from 'react-bootstrap'
import ComponentsService  from "../../../Services/ComponentsService"
import UserService  from "../../../Services/UserService"

interface FormInput {
    value: string,
    valid: boolean,
    error : string,
    validator: RegExp
}

interface FormSelect {
    value: string,
    valid: boolean
}

interface SignupForm {
    name: FormInput,
    email: FormInput,
    password: FormInput,
    cpu: FormSelect,
    gpu: FormSelect
}


const SignUp = () => {
    const history = useHistory();

    const [components, setComponent] = useState([]);

    useEffect(() => {
        ComponentsService.getAll().then (g =>{
            setComponent(g);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [form, setForm] = useState({
        name: {
            value: '',
            valid: true,
            error: 'Nombre no valido!',
            validator: /^[a-zA-Z\s]+$/
        },
        email: {
            value: '',
            valid: true,
            error: 'Email no valido!',
            // eslint-disable-next-line
            validator: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        password: {
            value: '',
            valid: true,
            error: 'Tu contraseña debe tener mínimo 8 caracteres y al menos una mayuscula, una minuscula y un numero, y no contener caracteres especiales',
            validator: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
        },
        cpu: {
            value: '',
            valid: true,
            error: 'CPU no valido',
            validator: /^\d+$/
        },
        gpu: {
            value: '',
            valid: true,
            error: 'GPU no valido',
            validator: /^\d+$/
        }
    } as SignupForm);

    const redirectToCatalog = useCallback(() => {
        history.replace("/Catalog");
    }, [history])

    const onChange = (field: keyof SignupForm) => {
        return (e: any) => {
            var value = e.target.value;
            var valid = false;
            var validator = (form[field] as FormInput).validator;
            if((form[field] as FormInput).validator.test(value)) {
                valid = true;
            } else {
                valid = false;
            }
            var error = valid  ? '': (form[field] as FormInput).error

            setForm({...form, [field]: {
                validator: validator,
                valid: valid,
                value: value,
                error: error
            }});
        }
    }

    const handleSigunp = (event: any) => {
        event.preventDefault();
        event.persist();
        let validity = true;
        Object.values(form).forEach(
            (val) => val.length > 0 && val.valid && (validity = false)
          );
          if(validity === true) {
             console.log("Yes validity");
          } else {
            console.log("No validity");
        }
        firebase
            .auth()
            .createUserWithEmailAndPassword(form.email.value, form.password.value)
            .then( () => {
                    firebase.auth().onAuthStateChanged(function(user) {
                        if (user) {
                            user.getIdToken().then(function(idToken) {
                                UserService.signUpUser(form.name.value, form.email.value, idToken, parseInt(form.cpu.value), parseInt(form.gpu.value)).then (g =>{
                                    console.log(g);
                                    history.replace("/Library");
                                })
                                .catch(error => {
                                    console.log("error");
                                });;
                            });
                        }
                    });
                })
            .catch(error => {
                console.log("error");
            });
    }

    const handleChangeToLogin = () => {
        history.push("/auth/login");
    }

    return (
        <>
            <div className="sig">
                <div className="gameGaTitle">
                    <h1>Game</h1>
                    <h1>Galaxy</h1>
                </div>
                <Form className="siForm" onSubmit={handleSigunp} noValidate>
                    <i className="fa fa-3x fa-wrench fa-gradient"></i>
                    <IoArrowBackCircle onClick={redirectToCatalog} className="backIcon" />
                    <h1 className="siTitle">Registro</h1>
                    <Form.Group>
                        <Form.Control type="text" name="name" className={ `form-control form-input ${ form.name.valid ? '' : 'form-invalid' }` }  placeholder="Nombre" onChange={ onChange("name") } />
                    </Form.Group>
                    {form.name.value.length > 0 &&  !form.name.valid && <p style={{color: "red"}}>{form.name.error}</p>}
                    <Form.Group>
                        <Form.Control type="email" name="email" className={ `form-control form-input ${ form.email.valid ? '' : 'form-invalid' }` }  placeholder="Email" onChange={ onChange("email") } />
                    </Form.Group>
                    {form.email.value.length > 0 &&  !form.email.valid && <p style={{color: "red"}}>{form.email.error}</p>}
                    <Form.Group>
                        <Form.Control type="password" name="password" className={ `form-control form-input ${ form.password.valid ? '' : 'form-invalid' }` }  placeholder="Contraseña" onChange={ onChange("password") } />
                    </Form.Group>
                    {form.password.value.length > 0 &&  !form.password.valid && <p style={{color: "red"}}>{form.password.error}</p>}
                    <div className="textS text-center p-2">
                        Componentes de tu PC
                    </div>
                    <Form.Group>
                        <Form.Control as="select" defaultValue="Default"className="my-1 mr-sm-2" name="cpu" onChange={ onChange("cpu") } custom >
                            <option disabled hidden value="Default">Procesador (CPU)</option>
                            {
                                components.sort((a, b) =>{
                                    if(a.model.toLowerCase() < b.model.toLowerCase()) return -1;
                                    if(a.model.toLowerCase() > b.model.toLowerCase()) return 1;
                                    return 0;
                                })
                                .map((e) => {
                                    // eslint-disable-next-line
                                    if (e.type == 0){
                                        return (<option  key={e.id} value={e.id}>{e.model}</option>);
                                    }
                                    return <></>
                                })
                            };
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control as="select" defaultValue="Default" className="my-1 mr-sm-2" name="gpu" onChange={ onChange("gpu") } custom >
                            <option hidden value="Default">Gráficos (GPU)</option>
                            {
                                components.sort((a, b) =>{
                                    if(a.model.toLowerCase() < b.model.toLowerCase()) return -1;
                                    if(a.model.toLowerCase() > b.model.toLowerCase()) return 1;
                                    return 0;
                                })
                                .map((e) => {
                                    // eslint-disable-next-line
                                    if (e.type == 1){
                                        return (<option  key={e.id} value={e.id}>{e.model}</option>);
                                    }
                                    return <></>
                                })
                            };
                        </Form.Control>
                    </Form.Group>
                    <div className="submitBtn">
                        <div className="center">
                            <button className="btnH">
                                <svg width="180px" height="40px" viewBox="0 0 180 40">
                                    <polyline points="179,1 179,39 1,39 1,1 179,1" className="bg-line" />
                                    <polyline points="179,1 179,39 1,39 1,1 179,1" className="hl-line" />
                                </svg>
                                <span>Crear cuenta</span>
                            </button>
                        </div>
                    </div>
                    <div className="textS text-center p-2">
                        ¿Ya tienes una cuenta?
                    </div>
                    <div className="changeBtn">
                        <div className="center">
                            <button className="btnH" onClick={handleChangeToLogin}>
                                <svg width="100px" height="30px" viewBox="0 0 100 30">
                                    <polyline points="99,1 99,29 1,29 1,1 99,1" className="bg-line" />
                                    <polyline points="99,1 99,29 1,29 1,1 99,1" className="hl-line" />
                                </svg>
                                <span>Iniciar sesión</span>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
}

export default SignUp;