import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ApplicationRoutes from "./config/ApplicationRoutes";
import './App.scss'

function App() {
  return <ApplicationRoutes />;
}

export default App;


