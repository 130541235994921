import Axios from "axios";

class PlayableService {

    url: string = "https://api.gamegalaxy.appyond.com/playable/";

    getIfPlayable(gameId: number, idToken: string): Promise<boolean> {
        return Axios.get(this.url + gameId, {
            headers: {
                Authorization: "Bearer " + idToken
            }
        }).then((data) => {
            return data.data.playable as boolean;
        });
    }

}

export default new PlayableService();