import React, { useEffect, useState } from 'react';
import Video1 from '../Components/Video1';
import GameDetails from '../Components/gameDetails';
import '../Components/videos.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import './GamePage.scss';
import { Game } from '../Models/Game';
import { YoutubeSearchResult } from '../Models/YoutubeSearchResults';
import GameService from '../Services/GameService';
import MediaService from '../Services/MediaService';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Spinner } from 'react-bootstrap';

export const GamePage = () => {
    let { id } = useParams() as any;
    const [game, setGame] = useState(new Game());
    useEffect(() => {
        GameService.getById(id).then(g => {
            console.log(g);
            setGame(g);
        });
    }, [id])


    const [video, setYoutubeSearchResults] = useState([] as YoutubeSearchResult[])
    useEffect(() => {
        MediaService.getVideos(id).then(v => {
            console.log(v);
            setYoutubeSearchResults(v.items!.slice(0,3));
        });
    }, [id])
    return (
        <> 
            { 
                game.title &&
                <div className="App-header">
                    <GameDetails game={game}></GameDetails>
                    <Container>
                        <h4 className="gradient-text-2">Videos</h4>
                        <Row>
                            {
                                video.map(v => {
                                    return( 
                                        <Col sm={12} md={4}>
                                            <Video1 id={v.id.videoId} key={v.id.videoId}></Video1>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Container>
                </div>
            }
            {
                !game.title &&
                <Row className='center-items' style={{ marginTop: '30px' }}>
                    <Spinner animation="border" role="status"></Spinner>
                </Row>
            }
        </>
    );
}