import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link, NavLink, useHistory } from 'react-router-dom';
import './Card.scss'
import AuthService from '../Services/AuthService';

export const NavigationBar = () => {

    const history = useHistory();
    const [isAdmin, setAdmin ] = useState(AuthService.isAdmin);
    const [signed, setSigned] = useState(AuthService.signedIn);
 
    useEffect(() => {
        setSigned(AuthService.signedIn);
        setAdmin(AuthService.isAdmin);
        var sub = AuthService.signedInObservable.subscribe((s) => {
            setSigned(s);
        })
        var subAdmin = AuthService.seeAdmin.subscribe((s) => {
            setAdmin(s);
        })
        return () => {
            sub.unsubscribe();
            subAdmin.unsubscribe();
        }
    }, [])

    const signOut = () => {
        AuthService.logOut()
        .then(() => {
            history.push('/auth/login')
        })
    }

    return (
        <>
            <div style={{ marginBottom: '80px' }}>
                    <Navbar className="justify-content-md-center game-nav" bg="secondary" variant="dark" fixed="top">
                        <Navbar.Brand as={Link} to='/' style={{
                            fontWeight: 700
                        }}>GameGalaxy</Navbar.Brand>
                        <Nav>
                            <Nav.Link as={NavLink} to='/catalog'>Catalogo</Nav.Link>
                            <Nav.Link as={NavLink} to='/library'>Biblioteca</Nav.Link>
                            {  isAdmin &&
                                <NavDropdown title="Opciones" id="nav-dropdown">
                                    <NavDropdown.Item as={Link} to='/admin/game/new' eventKey="4.1">Agregar Juego</NavDropdown.Item>
                                </NavDropdown>
                            }
                            {
                                (() => {
                                    if(signed) {
                                        return <Nav.Link onClick={signOut}>Cerrar Sesión</Nav.Link>
                                    } else {
                                        return <Nav.Link as={NavLink} to='/auth/login'>Iniciar Sesión</Nav.Link>
                                    }
                                })()
                            }
                        </Nav>
                    </Navbar>
                </div>
        </>
    ) 
}
