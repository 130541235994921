import UserRole from "../Enum/userRole";
import { Component } from "./Component";
import { LibraryGame } from "./LibraryGame";

export class User {
    id?: string;
    name?: string;
    email?: string;
    games?: LibraryGame[];
    CPU?: Component;
    GPU?: Component;
    roles?: UserRole[];
}