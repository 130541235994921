import Axios from "axios";
import { Platform } from "../Models/Platform";

class PlatformService {

    url: string = "https://api.gamegalaxy.appyond.com/platforms";

    getPlatforms(): Promise<Platform[]> {
        return Axios.get(this.url).then((data) => {
            return data.data as Platform[];
        });
    }

}

export default new PlatformService();