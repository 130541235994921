import firebase from "firebase";
import { Subject } from "rxjs";
import UserRole from "../Enum/userRole";
import { User } from "../Models/User";
import UserService from "./UserService";

class AuthService {

    signedIn: boolean = false;
    signedInObservable: Subject<boolean> = new Subject<boolean>();
    seeAdmin: Subject<boolean> = new Subject<boolean>();
    isAdmin: boolean = false;

    user: User = new User();
    userObservable: Subject<User> = new Subject<User>();

    constructor() {
        const firebaseConfig = require('../firebase-key.json');
        firebase.initializeApp(firebaseConfig);
        firebase.auth().onIdTokenChanged((user) => {
            if (user) {
                this.signedIn = true;
                this.signedInObservable.next(true);
                this.updateUserInfo();
            } else {
                this.seeAdmin.next(false);
                this.isAdmin = false;
                this.signedIn = false;
                this.signedInObservable.next(false);
                this.user = new User();
                this.userObservable.next();
            } 
        });
    }

    getUserInfo(token: string) {
        UserService.getUser(token).then(u => {
            this.user = u;
            this.userObservable.next(u);

            if (this.user.roles.includes(UserRole.Admin)) {
                this.isAdmin = true;
                this.seeAdmin.next(true)
            }
            else {
                this.isAdmin = false;
                this.seeAdmin.next(false)
            }
        })
        .catch(e => console.log(e));
    }

    updateUserInfo() {
        this.getToken()
            .then(t => this.getUserInfo(t));
    }

    getToken(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if(!this.signedIn) {
                reject("User must be signed");
            }
            firebase.auth().currentUser?.getIdToken().then(t => {
                resolve(t);
            });
        })
    }



    logOut(): Promise<void> {
        return firebase.auth().signOut()
    }

}

export default new AuthService();