import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import EstadoUserJuego from '../Components/bandera';
import { Game } from '../Models/Game';
import PlayableService from '../Services/PlayableService';
import AuthService from '../Services/AuthService';
import { Col, Container, Row } from 'react-bootstrap';
import './gameDetails.scss'
import EraseButton from './eraseButton';
import IconsBar from './IconsBar';

const GameDetails = (props: { game: Game }) => {
  var dateStr = '';
  if (props.game.launchDate) {
    var date = new Date(props.game.launchDate)
    dateStr = date.toLocaleDateString();
  }
  const idGame = props.game.id;
  const [estado, setEstado] = useState(false);
  const [knowIfPlayable, setKnowIfPlayable] = useState(false);
  const [isAdmin, setAdmin] = useState(AuthService.isAdmin);

  useEffect(() => {
    AuthService.getToken().then(token => {
      PlayableService.getIfPlayable(idGame!, token).then(d => {
        setEstado(d);
        setKnowIfPlayable(true);
      });
    });
    setAdmin(AuthService.isAdmin);
    var subAdmin = AuthService.seeAdmin.subscribe((s) => {
      setAdmin(s);
    })
    return () => {
      subAdmin.unsubscribe();
    }
    // eslint-disable-next-line
  }, [])

      useEffect(() => {
              AuthService.getToken().then(token => {
                console.log(token);
                PlayableService.getIfPlayable(idGame,token).then(d => {
                  console.log(d);
                  setEstado(d);
                });
              });
              // eslint-disable-next-line
      }, [])
    
  return (
    <Container>
      <div className="jumbotron jumbotron-fluid ">
        <Row>
          <Col sm={12} md={6} lg={4}>
            <img src={props.game.cover} className="cover" alt="logo" />
          </Col>
          <Col sm={12} md={6} lg={8}>
            <div className="details">
              <h1 className="title gradient-text-1">{props.game.title}</h1>
              {
                knowIfPlayable &&
                <EstadoUserJuego state={estado}></EstadoUserJuego>
              }
              <IconsBar p={props.game.platforms} g={props.game.id}></IconsBar>
              <h4 className=""> {props.game.developer}</h4>
              <h4 className=""> {props.game.genre}</h4>
              <h4 className=""> {dateStr}</h4>
              { isAdmin && <EraseButton id={idGame}></EraseButton>}
            </div>
          </Col>
        </Row>
        <Row>
          {
            props.game.minCPU &&
            <>
              <Col sm={12} md={8} lg={9}>
                <h4 className="gradient-text-2" style={{ marginTop: '40px' }}>Sinopsis</h4>
                <h5 className="description" style={{ paddingRight: '20px' }}>{props.game.description}</h5>
              </Col>
              <Col sm={12} md={4} lg={3} >
                <h4 className="gradient-text-2" style={{ marginTop: '40px' }}>Requerimentos de Sistema</h4>
                <h5 className="bold">Mínimos</h5>
                <h5 className="description">{props.game.minCPU?.model}</h5>
                <h5 className="description">{props.game.minGPU?.model}</h5>
                <h5 className="bold">Recomendados</h5>
                <h5 className="description">{props.game.recCPU?.model}</h5>
                <h5 className="description">{props.game.recGPU?.model}</h5>
              </Col>
            </>
          }
          {
            !props.game.minCPU &&
            <Col sm={12}>
              <h4 className="gradient-text-2" style={{ marginTop: '40px' }}>Sinopsis</h4>
              <h5 className="description" style={{ paddingRight: '20px' }}>{props.game.description}</h5>
            </Col>
          }
        </Row>
      </div>
    </Container>
  )
}

export default GameDetails;