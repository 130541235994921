import Axios from "axios";
import { User } from "../Models/User";

class PlatformService {

    url: string = "https://api.gamegalaxy.appyond.com/user";

    getUser(idToken: string): Promise<User> {
        return Axios.get(this.url, {
            headers: {
                Authorization: "Bearer " + idToken
            }
        }).then((data) => {
            return data.data as User;
        });
    }

    signUpUser(name: string, email: string, idToken: string, cpu: number, gpu: number): Promise<User> {
        console.log({
            name: name,
            email: email,
            CPU: cpu,
            GPU: gpu
        })
        return Axios.post(this.url, {
            name: name,
            email: email,
            CPU: cpu,
            GPU: gpu
        },{
            headers: {
                Authorization: "Bearer " + idToken
            }
        }).then((data) => {
            return data.data as User;
        });
    }

}

export default new PlatformService();